export default function(data, type, fileName) {
  console.log(data);
  console.log(type);
  console.log(fileName);
  const blob = new Blob([data], {
    type
  });
  const url = URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.download = fileName;
  element.href = url;
  clickCompatible(element);
  URL.revokeObjectURL(url);
}

function clickCompatible(dom) {
  if (document.all) {
    dom.click();
  } else {
    const evt = document.createEvent('MouseEvents');
    evt.initEvent('click', true, true);
    dom.dispatchEvent(evt);
  }
}
